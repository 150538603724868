@layer gainsight;

@layer gainsight {
  .apt-widget-container {
    left: auto !important;
    top: auto !important;
    right: var(--space-10) !important;
    bottom: var(--space-10) !important;
    transform: translate(0, 0) !important;
  }
}
